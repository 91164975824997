import _ from 'lodash';

export var flash = {
  methods: {
    flash(type, message) {            
      this.$buefy.toast.open({
        message: message, 
        type: 'is-' + type, 
      })
    },
    flashSuccess(message, position="is-top", duration=2000) {            
      this.$buefy.toast.open({
        message: message, 
        type: 'is-success', 
        position: position,
        duration: duration,
      })
    },
    flashError(err, position="is-top", duration=2000) { 
      var message = 'Ocorreu um erro!'

      if (!_.isEmpty(err)) {
        if (err instanceof String || typeof err === 'string') {
          message = err
        } else if (!_.isEmpty(err.body.message)) {
          if (err.body.message instanceof String  || typeof err.body.message === 'string') {
            message = err.body.message
          }
          
          if (err.body.message instanceof Object) {
            message = Object.values(err.body.message)[0];
          } 
        }
      }

      this.$buefy.toast.open({
        message: message, 
        type: 'is-danger', 
        position: position,
        duration: duration,
      })
    }
  }
};